import React from 'react';
import {
  // eslint-disable-next-line indent
  // eslint-disable-next-line no-trailing-spaces
  Container,
} from 'react-bootstrap';

const Error404 = () => (
  <Container>
    <h1>Error404</h1>
  </Container>
);

export default Error404;
